@font-face {
    font-family: "Mulish";
    src: url("../assets/Mulish-Bold.ttf") format("truetype");
    font-weight: bold;
}

body {
    margin: 0;

    font-family: "Mulish", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
}

.App {
    width: 100%;
    min-width: 320px;

    position: fixed;
    top: 50%;
    transform: translateY(-50%);

    padding: 0 5%;
    box-sizing: border-box;
}