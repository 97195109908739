.ToggleQuestion {
    border-radius: 20px;

    padding: 10px;
    box-sizing: border-box;

    h2, h3 {
        text-align: center;
        color: #FFFFFF;
    }

    .answers {
        width: 80%;
        max-width: 700px;

        margin: auto;
    }
}