.ToggleAnswer {
    margin: 10px 0;

    border-radius: 100px;
    border: 2px solid rgba(255, 255, 255, 0.5);

    // Set position to relative so that the slider can be absolutely positioned
    position: relative;

    .slider, .options {
        display: flex;
        flex-direction: row;
    }

    // CSS variable to control the transition duration
    --transition-duration: 0.5s;

    .slider {
        position: absolute;

        // Expand the slider to fill the size of the parent (driven by the options)
        width: 100%;
        height: 100%;

        // Passthrough click events
        pointer-events: none;

        // Animate changes to the slider padding either side of the button
        .sliderPadding {
            transition: flex-grow var(--transition-duration);
        }

        // Slider cell that shows behind the selected option
        .sliderCell {
            flex-grow: 1;

            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    .options {
        // Vertically stretch the options to fill the parent
        align-items: stretch;

        .option {
            text-align: center;
            color: #FFFFFF;
            font-size: 1.05em;

            flex-grow: 1;
            flex-basis: 0;

            box-sizing: border-box;
            padding: 15px;
            
            transition: color var(--transition-duration);

            // Vertically center the text
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

// When the width of the screen is less than 700px, the options should be stacked vertically
@media (max-width: 700px) {
    .ToggleAnswer {
        border-radius: 15px;

        // Hide overflow to clip the slider cell
        overflow: hidden;

        // Faster transition in the vertical layout
        --transition-duration: 0.3s;

        .slider {
            flex-direction: column;

            .sliderCell {
                // No border radius on the slider cell - clipped by the parent
                border-radius: 0;
            }
        }

        .options {
            // Use a grid layout to stack the options vertically with equal height
            display: grid;

            grid-template-columns: 1fr;
            grid-auto-rows: 1fr;

            .option {
                padding: 10px;
            }
        }
    }
}